<template>
  <q-form ref="editForm">
    <c-card title="행동관찰 총평" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="!disabled && editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="tabParam"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveCause"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="우선사항"
            name="priorityContents"
            v-model="tabParam.priorityContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="개선사항"
            name="improveContents"
            v-model="tabParam.improveContents"
          />
        </div>
      </template>
    </c-card>
    <c-card title="불안전한 행동의 근본원인" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            codeGroupCd="CTO_WORK_CHARACTER_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="workCharacteristics"
            label="작업특성"
            v-model="tabParam.workCharacteristics">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            codeGroupCd="CTO_HUMAN_FACTOR_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="humanFactors"
            label="인적요소"
            v-model="tabParam.humanFactors">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-multi-select
            codeGroupCd="CTO_CORE_ACTION_ITEMS_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="coreActionItems"
            label="핵심행동항목"
            v-model="tabParam.coreActionItems">
          </c-multi-select>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'cto-cause',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
        improveContents: '',
        priorityContents: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        ctoCompleteFlag: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [],
        data: [],
        height: '800px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.cto.update.url',
      saveType: 'PUT',
      isSave: false,
      title: '',
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.ctoCompleteFlag) && this.tabParam.ctoCompleteFlag === 'Y'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.cto.insert.url;
      this.updateUrl = transactionConfig.sop.cto.update.url;
      // list setting
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('CTO_GOOD_BAD_CD').then(_item => {
        this.grid.columns = [
          {
            name: 'checklistItemName',
            field: 'checklistItemName',
            label: '체크리스트 항목',
            align: 'left',
            style: 'width:600px',
            sortable: false,
          },
          {
            name: 'selectValue',
            field: 'selectValue',
            label: '양호/불량/해당없음',
            type: 'select',
            setHeader: true,
            style: 'width:200px',
            align: 'center',
            comboItems: _item,
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            type: 'textarea',
            style: 'width:500px',
            align: 'left',
            sortable: false
          },
        ]
      });
    },
    /* eslint-disable no-unused-vars */ 
    saveCause() {
      if (this.tabParam.sopCtoId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId
              this.tabParam.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
